class ApiError extends Error {
  constructor(e) {
    if (e instanceof ApiError || e.error) {
      if (e.detail) {
        super(`${e.title} ${e.detail}`);
      } else {
        super(e.title);
      }

      Object.assign(this, e);
      delete this.error;
      delete this.status;
    } else if (e.isAxiosError) {
      super(e.message);

      this.request  = e.request;
      this.response = e.response;
    } else if (e instanceof Error) {
      super(e.message);

      this.inner = e;
    } else if (e.message) {
      super(e.message);
    } else {
      super("Unknown error.");
    }
  }
}

class AddressUnknownError extends ApiError {
  constructor(e) {
    super(e);
  }
}

class AlreadyHaveOrderError extends ApiError {
  constructor(e) {
    super(e);
  }
}

class CustomerSuspendedError extends ApiError {
  constructor(e) {
    super(e);

    this.until  = e.until;
    this.reason = e.reason;
  }
}

const errorTypes = {
  addressUnknown: "https://api.taxibarby.ro/docs/dispatch/errors/orders/address-unknown",
  alreadyHaveOrder: "https://api.taxibarby.ro/docs/dispatch/errors/orders/already-have-order",

  customerSuspended: "https://api.taxibarby.ro/docs/dispatch/errors/csutomers/suspended",
};

const errorMappings = {
  [errorTypes.addressUnknown]: AddressUnknownError,
  [errorTypes.alreadyHaveOrder]: AlreadyHaveOrderError,

  [errorTypes.customerSuspended]: CustomerSuspendedError,
};

function newApiError(response) {
  if (response instanceof ApiError) return response;
  if (response instanceof Error) return new ApiError(response);

  // Check if it's an error.
  if (!response.error) {
    throw Object.assign(new Error("Response is not an error."), { response });
  }

  // Check if it's an API error.
  if (response.type && response.title) {
    const errorClass = errorMappings[response.type];

    if (errorClass) {
      return new errorClass(response);
    }
  }

  return new ApiError(response);
}

export {
  newApiError,

  ApiError,
  ApiError as default,

  AddressUnknownError,
  AlreadyHaveOrderError,
  CustomerSuspendedError,
};
